"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _reactIs = require("react-is");

/**
 * A factory that returns a propTypes validator that only accepts values that
 * are also accepted by React.createElement
 * e.g. "div", functional, class components, forwardRef etc.
 *
 * @param {boolean} isRequired If `true` returns a validator
 *                             that will throw if nullish values are passed
 */
function createComponentProp(isRequired) {
  /* istanbul ignore if */
  if (process.env.NODE_ENV === 'production') {
    return function () {
      return null;
    };
  }

  return function componentPropType(props, key, componentName, location, propFullName) {
    var prop = props[key];
    var propName = propFullName || key;
    var message;

    if (prop == null) {
      if (isRequired) {
        message = "The ".concat(location, " `").concat(propName, "` is marked as required in `").concat(componentName, "`, ") + "but its value is `".concat((0, _typeof2.default)(prop), "`.");
      }
    } else if (!(0, _reactIs.isValidElementType)(prop)) {
      var preciseType = (0, _typeof2.default)(prop);
      message = "Invalid ".concat(location, " `").concat(propName, "` of type `").concat(preciseType, "` ") + "supplied to `".concat(componentName, "`, expected a component.");
    }

    if (message != null) {
      // change error message slightly on every check to prevent caching when testing
      // which would not trigger console errors on subsequent fails
      return new Error("".concat(message).concat(process.env.NODE_ENV === 'test' ? Date.now() : ''));
    }

    return null;
  };
}

var componentPropType = createComponentProp(false);
componentPropType.isRequired = createComponentProp(true);
var _default = componentPropType;
exports.default = _default;