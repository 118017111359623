"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFunctionName = getFunctionName;
exports.default = void 0;
// Fork of recompose/getDisplayName with added IE 11 support
// Simplified polyfill for IE 11 support
// https://github.com/JamesMGreene/Function.name/blob/58b314d4a983110c3682f1228f845d39ccca1817/Function.name.js#L3
var fnNameMatchRegex = /^\s*function(?:\s|\s*\/\*.*\*\/\s*)+([^(\s/]*)\s*/;

function getFunctionName(fn) {
  var match = "".concat(fn).match(fnNameMatchRegex);
  var name = match && match[1];
  return name || '';
}

function getDisplayName(Component) {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return undefined;
  }

  return Component.displayName || Component.name || getFunctionName(Component) || 'Component';
}

var _default = getDisplayName;
exports.default = _default;