"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// Share the same values than in
// https://github.com/cssinjs/jss/blob/master/packages/react-jss/src/ns.js
var ns = {
  jss: '64a55d578f856d258dc345b094a2a2b3',
  sheetsRegistry: 'd4bd0baacbc52bbd48bbb9eb24344ecd',
  sheetOptions: '6fc570d6bd61383819d0f9e7407c452d'
};
var _default = ns;
exports.default = _default;