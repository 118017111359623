"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isBody = isBody;
exports.default = isOverflowing;

var _isWindow = _interopRequireDefault(require("dom-helpers/query/isWindow"));

var _ownerDocument = _interopRequireDefault(require("../utils/ownerDocument"));

var _ownerWindow = _interopRequireDefault(require("../utils/ownerWindow"));

function isBody(node) {
  return node && node.tagName.toLowerCase() === 'body';
} // Do we have a vertical scroll bar?


function isOverflowing(container) {
  var doc = (0, _ownerDocument.default)(container);
  var win = (0, _ownerWindow.default)(doc);
  /* istanbul ignore next */

  if (!(0, _isWindow.default)(doc) && !isBody(container)) {
    return container.scrollHeight > container.clientHeight;
  } // Takes in account potential non zero margin on the body.


  var style = win.getComputedStyle(doc.body);
  var marginLeft = parseInt(style.getPropertyValue('margin-left'), 10);
  var marginRight = parseInt(style.getPropertyValue('margin-right'), 10);
  return marginLeft + doc.body.clientWidth + marginRight < win.innerWidth;
}